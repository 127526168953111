import styled from 'styled-components';
import { Common, color, device } from '@stockbit/ui';
import Image from 'global/Image';
import { Wrapper, SectionTitle } from '../styled';

const { Flex, Text } = Common;

const SectionWrapper = styled(Wrapper).attrs({
  align: 'center',
})`
  @media ${device.laptop} {
    flex-direction: row;
  }
`;

const ContentWrapper = styled(Flex).attrs({
  direction: 'column',
})`
  align-items: center;
  margin-bottom: 85px;
  width: 100%;

  @media ${device.laptop} {
    align-items: flex-start;
    margin-bottom: 0px;
    padding-right: 50px;
    width: 50%;
  }

  @media ${device.laptopL} {
    width: 60%;
  }
`;

const PhoneWrapper = styled(Flex)`
  justify-content: center;
  position: relative;
  width: 100%;

  @media ${device.laptop} {
    justify-content: flex-end;
    padding-left: 50px;
    width: 50%;
  }

  @media ${device.laptopL} {
    width: 40%;
  }
`;

const Title = styled(SectionTitle)`
  margin-bottom: 35px;
  text-align: center;

  @media ${device.laptop} {
    margin-bottom: 5vh;
    text-align: left !important;
  }
`;

const Description = styled(Text).attrs({
  family: 'light',
})`
  font-size: 17px;
  text-align: center;

  @media ${device.laptop} {
    font-size: 19px;
    text-align: left;
  }
`;

const PhoneFrame = styled(Flex).attrs({
  align: 'center',
  justify: 'center',
  direction: 'column',
})`
  background: ${color.component};
  border-radius: 44px;
  box-shadow: 0 0 3px rgb(0 0 0 / 12%), 0 -1px 2px rgb(0 0 0 / 5%),
    0 1px 2px rgb(0 0 0 / 24%);
  position: relative;
  width: 300px;
  z-index: 2;

  img {
    width: 100%;
    opacity: 0;
  }
`;

const PhoneContent = styled(Flex).attrs({
  align: 'center',
  justify: 'space-evenly',
  direction: 'column',
})`
  position: absolute;
  height: 100%;
`;

const PhoneTop = styled(Flex).attrs({
  align: 'center',
  justify: 'center',
})`
  height: 42px;
  width: 100px;

  .sensor,
  .speaker {
    background: #ddd;
    border: solid 1px ${color.gainsboro};
    margin: 0 5px;
  }

  .sensor {
    border-radius: 50%;
    height: 12px;
    width: 12px;
  }

  .speaker {
    border-radius: 5px;
    height: 7px;
    width: 50px;
  }
`;

const PhoneScreen = styled.div`
  background: #fff no-repeat center top / 100% auto;
  box-shadow: inset 0 -1px 1px rgb(0 0 0 / 10%),
    inset 0 1px 1px rgb(0 0 0 / 10%), inset -1px 0px 1px rgb(0 0 0 / 10%),
    inset 1px 0px 1px rgb(0 0 0 / 10%);
  width: 88%;

  img {
    width: 100%;
    opacity: 0;
  }
`;

const PhoneButton = styled.div`
  background: ${color.light};
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 10%);
  width: 42px;
  height: 42px;
  margin: 0 auto;
  bottom: 10px;
  border-radius: 50%;
  border: solid 1px ${color.gainsboro};
`;

const PanelBox = styled(Flex).attrs({
  direction: 'column',
  align: 'center',
})`
  position: absolute;
  width: 265px;
  z-index: 3;

  .box-1 {
    transform: rotate(-2deg) translate(-30px, -50px);
  }

  .box-2 {
    transform: scale(0.95) rotate(3deg) translate(60px, 0);
  }

  @media ${device.tablet} {
    width: 300px;
  }

  @media ${device.laptopL} {
    transform: translateX(-300px);
  }
`;

const Illustration = styled(Image)`
  display: none;
  height: 300px;
  position: absolute;
  z-index: 1;

  @media ${device.tablet} {
    display: block;
    height: 400px;
    left: 100px;
    right: 0;
    top: 70px;
  }
  @media ${device.laptop} {
    height: 380px;
    left: 0px;
  }
  @media ${device.laptop} {
    height: 420px;
    left: -75px;
  }
`;

export {
  SectionWrapper,
  PhoneFrame,
  Title,
  Description,
  ContentWrapper,
  PhoneTop,
  PhoneWrapper,
  PhoneScreen,
  PhoneButton,
  PhoneContent,
  PanelBox,
  Illustration,
};
