import { color } from '@stockbit/ui';
import styled from 'styled-components';

const BoxWrapper = styled.div`
  background: ${color.component};
  border-radius: 4px;
  box-shadow: 0 0 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  margin: 20px 0;
  max-width: 840px;
  overflow: hidden;
  padding: 8px;
  width: 100%;
`;

const TabToolTop = styled.div`
  border-radius: 4px 4px 0 0;
  height: 24px;
  padding: 6px 3px;

  .browser-bullet {
    background: #2f8ef7;
    border-radius: 10px;
    float: left;
    height: 7px;
    margin-left: 7px;
    opacity: 0.8;
    vertical-align: middle;
    width: 7px;

    :nth-child(1) {
      background: #fc605c;
    }
    :nth-child(2) {
      background: #fdbc40;
    }
    :nth-child(3) {
      background: #34c84a;
    }
  }
`;

const BrowserContent = styled.div`
  background: ${color.light} no-repeat center top / 100% auto;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 10%);
  height: 100%;
  overflow: hidden;
  position: relative;

  img {
    width: 98%;
    opacity: 0;
  }
`;

export { BoxWrapper, TabToolTop, BrowserContent };
