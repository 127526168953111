import { Common, color, device } from '@stockbit/ui';
import styled from 'styled-components';

const { Flex, Text, IconWrapper: BaseIconWrapper } = Common;

const Wrapper = styled(Flex)`
  background: ${color.light};
  border: solid ${color.gainsboro};
  border-width: 1px;
  margin-bottom: 10px;
  padding: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    border-width: 2px;
    padding: 10px 20px;
  }
`;

const TextContent = styled(Text).attrs({
  size: '15px',
  mb: '5px',
})`
  b {
    font-family: var(--bold-font);
  }
`;

const IconWrapper = styled(BaseIconWrapper)`
  align-items: center;
  border: solid 1px ${color.midgray};
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  height: 30px;
  margin-right: 10px;
  justify-content: center;
  width: 30px;
`;

export { Wrapper, TextContent, IconWrapper };
