import { Common, color } from '@stockbit/ui';
import { Wrapper, TextContent, IconWrapper } from './styled';

const { Flex, Iconmoon, Text } = Common;

interface PanelProps {
  symbol: string;
  time: string;
  description: string;
  [key: string]: any;
}

function Panel({ symbol, time, description, ...props }: PanelProps) {
  return (
    <Wrapper {...props}>
      <IconWrapper>
        <Iconmoon className="icon-avatar_report" />
      </IconWrapper>
      <Flex direction="column">
        <TextContent>
          <b>{symbol}</b> {description} [{symbol}]
        </TextContent>
        <Text color={color.midgray} size="12px">
          {time}
        </Text>
      </Flex>
    </Wrapper>
  );
}

export default Panel;
